import { useLocation } from '@remix-run/react'
import Body from '#app/components/typography/body'
import { cn } from '#app/utils/misc'
import { type ClerkProduct } from '#types/product'

interface DiscountLabelProps {
	product: ClerkProduct
}

const DiscountLabel = ({ product }: DiscountLabelProps) => {
	const location = useLocation()

	const searchParams = new URLSearchParams(location.search)
	const storeParam = searchParams.get('store')
	let price = product.price // Default to the global lowest price
	let listPrice = product.list_price // Default to the global original price
	let isDiscounted = product.on_sale

	// Check if there is a specific store in the URL parameters
	if (storeParam) {
		const storeData = JSON.parse(product.data)
		const storePricing = storeData.find(
			(store: any) => store.store_key === storeParam,
		)
		if (storePricing) {
			// Find the lowest price in the store-specific data
			const storePrices = storePricing.prices
			const lowestStorePrice = storePrices.reduce((lowest: any, item: any) => {
				return item.price < lowest.price ? item : lowest
			}, storePrices[0])

			price = lowestStorePrice.price
			listPrice = lowestStorePrice.list_price
			isDiscounted = lowestStorePrice.on_sale
		}
	}

	let discountPercentage = 0
	if (isDiscounted) {
		discountPercentage = ((listPrice - price) / listPrice) * 100
		discountPercentage = Math.round(discountPercentage)
	}

	return (
		<>
			{isDiscounted && (
				<div
					className={cn(
						'absolute bottom-2 z-20 rounded-[40px] bg-mint-green-80p px-4 py-[2px] opacity-80 md:bottom-4',
					)}
				>
					<Body body={`${discountPercentage}%`} as="xs" />
				</div>
			)}
		</>
	)
}

export default DiscountLabel
