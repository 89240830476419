import React from 'react'
import { useTranslation } from 'react-i18next'
import Body from '#app/components/typography/body'
import { type ClerkProduct } from '#types/product'

interface NewLabelProps {
	product: ClerkProduct
}

const NewLabel = ({ product }: NewLabelProps) => {
	const sevenDaysInSeconds = 7 * 24 * 60 * 60
	const nowInSeconds = Math.floor(Date.now() / 1000)
	const isNew = nowInSeconds - product.created_at <= sevenDaysInSeconds
	let { t } = useTranslation('product_list_page')

	if (!isNew) {
		return null
	}

	return (
		<div className="absolute left-2 md:left-4 md:top-4 top-2 z-20 rounded-[40px] bg-orange-10 px-4 py-[2px] text-mint-green-80p opacity-80">
			<Body body={t('label_new')} as="xs" />
		</div>
	)
}

export default NewLabel
