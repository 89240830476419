import { cn } from '#app/utils/misc'
import { type ClerkProduct } from '#types/product'
import LowInStockLabel from './low-in-stock'
import DiscountLabel from './product-discount'

const BottomLabels = ({
	product,
	hidden,
}: {
	product: ClerkProduct
	hidden?: boolean
}) => {
	return (
		<div
			className={cn(
				'absolute left-2 z-20 transition-all duration-300 ease-in-out md:left-4',
				hidden ? 'bottom-0 md:bottom-[19%]' : 'bottom-0',
			)}
		>
			<div>
				<LowInStockLabel product={product} hidden={hidden} />
				<DiscountLabel product={product} />
			</div>
		</div>
	)
}

export default BottomLabels
