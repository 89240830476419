import { cn } from '#app/utils/misc'

interface BodyProps extends React.HTMLAttributes<HTMLParagraphElement> {
	size?: 'lg' | 'md' | 'sm' | 'xs'
}

const PlentyBody = ({
	size = 'md',
	className,
	children,
	...rest
}: BodyProps) => {
	switch (size) {
		case 'lg':
			return (
				<p
					className={cn(
						`text-[16px] leading-[16px] tracking-[-0.24px] md:text-[18px] md:leading-[22px] md:tracking-[-0.27px]`,
						className,
					)}
					{...rest}
				>
					{children}
				</p>
			)
		case 'md':
			return (
				<p
					className={cn(
						`text-[14px] leading-[16px] tracking-[-0.21px] md:text-[16px] md:leading-[22px] md:tracking-[-0.24px] `,
						className,
					)}
					{...rest}
				>
					{children}
				</p>
			)
		case 'sm':
			return (
				<p
					className={cn(
						`leading-[16px] tracking-[-0.18px] md:text-[14px] md:leading-[22px] md:tracking-[-0.21px] `,
						className,
					)}
					{...rest}
				>
					{children}
				</p>
			)
		case 'xs':
			return (
				<p
					className={cn(
						`text-[10px] leading-[16px] tracking-[-0.15px] md:text-[12px] md:leading-[14px] md:tracking-[-0.18px]`,
						className,
					)}
					{...rest}
				>
					{children}
				</p>
			)
	}
}

export default PlentyBody
